const links = {
    dev: {
      base_url: "https://back.super-senses.com/v1/",
      image_base_url: "https://doma4ps7s7wig.cloudfront.net/"
    },
    prod: {
      base_url: "http://34.198.213.10:5075/v1/",
      image_base_url: "https://d1kmto3gz03262.cloudfront.net/"
    }
  }


export const image_base_url = links.dev.image_base_url
// // // // local
export const base_url = "https://supersenses.centy.so/v1/"

//export const base_url = links.dev.base_url
