import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import OrdersPage from './pages/Orders';
import SubsPage from './pages/SubsPage';
import SettingPage from './pages/SettingPage';
import SupportsPage from './pages/SupportPage';
// import UserHistoryPage from './pages/UserHistoryPage';
import IntroPage from './pages/IntrosPage';
import UserVerfications from './pages/UserVerifications';
import FeedPage from './pages/FeedPage';
import UserDetailView from './pages/userDetailed';

// Other imports...



// ----------------------------------------------------------------------

export default function Router() {
  const navigate=useNavigate()
  const auth=useSelector(state=>state.auth)
  
  useEffect(()=>{
    if(auth?.authorize){
      navigate("/dashboard",{replace:true})
    }else{
      navigate("/login",{replace:true})
    }
  },[auth])
  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'user/:userId',  element: <UserDetailView/> } ,
        { path: 'products', element: <OrdersPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'subs', element: <SubsPage /> },
        { path: 'settings', element: <SettingPage /> },
        { path: 'supports', element: <SupportsPage /> },
        { path: 'intros', element: <IntroPage /> },
        { path: 'verifications', element: <UserVerfications /> },
        { path: 'feeds', element: <FeedPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path:'404', element: <Page404 /> },
        { path:'*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
